import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const plaeSizes = [
  'YS',
  'YM',
  'YL',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
  '5 - tiny-tots',
  '5.5 - tiny-tots',
  '6 - tots',
  '6.5 - tots',
  '7 - tots',
  '7.5 - tots',
  '8 - kids',
  '8.5 - kids',
  '9 - kids',
  '9.5 - kids',
  '10 - kids',
  '10.5 - kids',
  '11 - kids',
  '11.5 - kids',
  '12 - kids',
  '12.5 - kids',
  '13 - kids',
  '13.5 - kids',
  '1 - youth',
  '1.5 - youth',
  '2 - youth',
  '2.5 - youth',
  '3 - youth',
  '3.5 - youth',
  '4 - youth',
  '4.5 - youth',
  '4 Mens = 5.5 Womens',
  '4.5 Mens = 6 Womens',
  '5 Mens = 6.5 Womens',
  '5.5 Mens = 7 Womens',
  '6 Mens = 7.5 Womens',
  '6.5 Mens = 8 Womens',
  '7 Mens = 8.5 Womens',
  '7.5 Mens = 9 Womens',
  '8 Mens = 9.5 Womens',
  '8.5 Mens = 10 Womens',
  '9 Mens = 10.5 Womens',
  '9.5 Mens = 11 Womens',
  '10 Mens = 11.5 Womens',
  '10.5 Mens = 12 Womens',
  '11 Mens = 12.5 Womens',
  '11.5 Mens = 13 Womens',
  '12 Mens = 13.5 Womens',
  '13 Mens = 14.5 Womens',
  '14 Mens = 15.5 Womens',
  '15 Mens = 16.5 Womens',
];

const categoryPercentages = {
  'kid styles': 0.02,
  'shoes kid': 0.02,
  'adult styles': 0.01,
  'shoes adult': 0.01,
};

const tagPercentages = {
  '__label:almost gone': 0.04,
  just_arrived: 0.04,
  Vegan: 0.02,
  Features_vegan: 0.02,
  'Style_thandi wp': 0.009,
  'thandi wp': 0.009,
  YGroup_thandi: 0.009,
  marten: 0.008,
  Style_marten: 0.008,
  YGroup_marten: 0.008,
  'kaiden wp': 0.007,
  'Style_kaiden wp': 0.007,
  Girls: 0.005,
  Big_Kids: 0.005,
  Boys: 0.005,
  Unisex: 0.005,
  YGroup_kaiden: 0.005,
  lou: 0.004,
  Style_lou: 0.004,
  Style_migi: 0.004,
  YGroup_migi: 0.004,
  crosby: 0.003,
  'features_lace catcher': 0.003,
  sam: 0.003,
  'Style_sam-2-0': 0.003,
  YGroup_sam20: 0.003,
  SS24: 0.003,
  roan: 0.003,
  Style_roan: 0.003,
  YGroup_roan: 0.003,
  'Style Type_flat': 0.003,
  Style_crosby: 0.003,
  YGroup_crosby: 0.003,
  'Plae Color_Navy': 0.003,
  july23: 0.003,
  aw20: 0.003,
  ss20: 0.003,
  BFCM20: 0.003,
  'Style_tab - suede': 0.003,
  'tab - suede': 0.003,
  Color_brown: 0.003,
  prospect: 0.003,
  Style_prospect: 0.003,
  YGroup_prospect: 0.003,
  'Style Type_boots': 0.003,
  Color_gold: 0.003,
  Target: 0.003,
  mimo: 0.003,
  Style_mimo: 0.003,
  YGroup_mimo: 0.003,
  bolts: 0.003,
  features_staelace: 0.003,
  YGroup_bolts: 0.003,
  Color_silver: 0.003,
  Color_yellow: 0.003,
  active: 0.003,
  Color_tan: 0.003,
  ARCHIVE: 0.003,
  'Features_collapsible heel': 0.003,
  Features_staelace: 0.003,
  'Features_lace catcher': 0.003,
  'Plae Color_Black': 0.003,
  'Size_One Size': 0.003,
  move: 0.003,
  Boots: 0.003,
  Packables: 0.003,
  Color_orange: 0.003,
  'Style Type_mary janes': 0.003,
  aw19: 0.003,
  accessories: 0.003,
  SS20: 0.003,
  YGroup_max: 0.003,
  Color_white: 0.003,
  Color_navy: 0.003,
  Style_max: 0.003,
  'Style Type_sandal': 0.003,
  arcade: 0.003,
  'Features_water friendly': 0.003,
  Style_: 0.003,
  FriendsEvent: 0.003,
  Features_waterproof: 0.003,
  SS19: 0.003,
  'Features_cascade dafo approved': 0.003,
  Style_ty: 0.003,
  ty: 0.003,
  YGroup_ty: 0.003,
  Color_print: 0.003,
  barrett: 0.003,
  Color_green: 0.003,
  AFO: 0.003,
  Orthotics: 0.003,
  Adaptive: 0.003,
  Color_red: 0.003,
  GR8: 0.003,
  Color_purple: 0.003,
  BFE_2019_Public: 0.003,
  Color_black: 0.003,
  BFE_2019: 0.003,
  Image_Square: 0.003,
  BFCM50: 0.003,
  YGroup_tabs1: 0.003,
  GMAT: 0.003,
  Color_pink: 0.003,
  Color_blue: 0.003,
  Color_grey: 0.003,
  'Features_spot clean': 0.003,
  Features_washable: 0.003,
  Washable: 0.003,
  tabs: 0.003,
  Active: 0.003,
  'Features_active traction': 0.003,
  'Style Type_hi tops': 0.002,
  clothing: 0.002,
  mulberry: 0.002,
  Style_mulberry: 0.002,
  YGroup_mulberry: 0.002,
  Features_lightweight: 0.001,
  'Features_removable insoles': 0.001,
  Size_YL: 0.0005,
  Size_YM: 0.0005,
  Size_YS: 0.0005,
  Size_S: 0.0005,
  Size_M: 0.0005,
  Size_L: 0.0005,
  Size_XL: 0.0005,
  'Size_5 - tiny-tots': 0.0005,
  'Size_5.5 - tiny-tots': 0.0005,
  'Size_6 - tots': 0.0005,
  'Size_7 - tots': 0.0005,
  'Size_6.5 - tots': 0.0005,
  'Size_7.5 - tots': 0.0005,
  'Size_1 - youth': 0.0005,
  'Size_2 - youth': 0.0005,
  'Size_3 - youth': 0.0005,
  'Size_3.5 - youth': 0.0005,
  'Size_4 - youth': 0.0005,
  'Size_4.5 - youth': 0.0005,
  'Size_10.5 - kids': 0.0005,
  'Size_11.5 - kids': 0.0005,
  'Size_8.5 - kids': 0.0005,
  'Size_9.5 - kids': 0.0005,
  'Size_1.5 - youth': 0.0005,
  'Size_2.5 - youth': 0.0005,
  'Size_12.5 - kids': 0.0005,
  'Size_13.5 - kids': 0.0005,
  'Size_8 - kids': 0.0005,
  'Size_9 - kids': 0.0005,
  'Size_10 - kids': 0.0005,
  'Size_11 - kids': 0.0005,
  'Size_12 - kids': 0.0005,
  'Size_13 - kids': 0.0005,
  'shoes kid': 0.0005,
  'Style Type_sneakers': 0.0005,
  'Size_LG [shoe sizes 13 - 3]': 0.0005,
  'Size_MED [shoe sizes 8 - 12.5]': 0.0005,
  'Size_SM [shoe sizes 6 - 7.5]': 0.0005,
  'Size_XL [shoe size 3.5 - 4.5 wide]': 0.0005,
  'Size_10 Womens': 0.0005,
  'Size_10.5 Mens': 0.0005,
  'Size_11 Womens': 0.0005,
  'Size_11.5 Mens': 0.0005,
  'Size_12 Womens': 0.0005,
  'Size_13 Womens': 0.0005,
  'Size_4.5 Mens': 0.0005,
  'Size_5.5 Mens': 0.0005,
  'Size_6 Womens': 0.0005,
  'Size_6.5 Mens': 0.0005,
  'Size_7 Womens': 0.0005,
  'Size_7.5 Mens': 0.0005,
  'Size_8 Womens': 0.0005,
  'Size_8.5 Mens': 0.0005,
  'Size_9 Womens': 0.0005,
  'Size_9.5 Mens': 0.0005,
  'shoes adult': 0.0005,
  'Size_10 Mens': 0.0005,
  'Size_10.5 Womens': 0.0005,
  'Size_11 Mens': 0.0005,
  'Size_11.5 Womens': 0.0005,
  'Size_12 Mens': 0.0005,
  'Size_12.5 Womens': 0.0005,
  'Size_13 Mens': 0.0005,
  'Size_13.5 Womens': 0.0005,
  'Size_14 Mens': 0.0005,
  'Size_14.5 Womens': 0.0005,
  'Size_15.5 Womens': 0.0005,
  'Size_5 Mens': 0.0005,
  'Size_4 Mens': 0.0005,
  'Size_5.5 Womens': 0.0005,
  'Size_6 Mens': 0.0005,
  'Size_6.5 Womens': 0.0005,
  'Size_7 Mens': 0.0005,
  'Size_7.5 Womens': 0.0005,
  'Size_8 Mens': 0.0005,
  'Size_8.5 Womens': 0.0005,
  'Size_9 Mens': 0.0005,
  'Size_9.5 Womens': 0.0005,
  'mens tee': 0.0005,
  'womens tee': 0.0005,
  'Default Size Gender_Womens': 0.0005,
  Features_flexible: -0.01,
  emme: -0.01,
  'Style_tab - high burst poly': -0.01,
  'tab - high burst poly': -0.01,
  Style_emme: -0.01,
  YGroup_emme: -0.01,
  nat: -0.01,
  Style_nat: -0.01,
  YGroup_nat: -0.01,
  chloe: -0.02,
  Style_miles: -0.02,
  YGroup_miles: -0.02,
  Style_chloe: -0.02,
  YGroup_chloe: -0.02,
  Features_breathable: -0.03,
};

export const plaeShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: plaeSizes }),
    getBrandDirectFilterConfig(),
  ],
  resaleValueConfig: {
    categoryPercentages,
    tagPercentages,
  },
};
