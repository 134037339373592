import React from 'react';
import { bool, elementType, object, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  ensureCurrentUser,
  ensureUser,
  userAbbreviatedName,
  userDisplayNameAsString,
} from '../../util/data';
import { IconBannedUser, NamedLink, ResponsiveImage } from '..';

import css from './Avatar.module.css';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

export const AvatarComponent = (props) => {
  const {
    className,
    sizeClassName,
    user,
    renderSizes,
    disableProfileLink,
    intl,
    noProfileImageIcon,
  } = props;

  const classes = classNames(css.root, className, sizeClassName);
  const userIsCurrentUser = user && user.type === 'currentUser';
  const avatarUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const isBannedUser = avatarUser.attributes.banned;
  const isDeletedUser = avatarUser.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  const deletedDisplayName = isDeletedUser ? deletedUserDisplayName : '';
  const defaultUserDisplayName = isBannedUser ? bannedUserDisplayName : deletedDisplayName;

  const defaultUserAbbreviatedName = '';

  const displayName = userDisplayNameAsString(avatarUser, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(avatarUser, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };
  const linkProps = avatarUser.id
    ? { name: 'ProfilePage', params: { id: avatarUser.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = avatarUser.profileImage && avatarUser.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  }

  if (hasProfileImage) {
    const image = (
      <ResponsiveImage
        rootClassName={css.avatarImage}
        alt={displayName}
        image={avatarUser.profileImage}
        variants={AVATAR_IMAGE_VARIANTS}
        sizes={renderSizes}
      />
    );

    return profileLinkEnabled ? (
      <NamedLink {...rootProps} {...linkProps}>
        {image}
      </NamedLink>
    ) : (
      <div {...rootProps}>{image}</div>
    );
  }

  if (noProfileImageIcon) {
    const image = <span className={css.avatarImage}>{noProfileImageIcon}</span>;

    return profileLinkEnabled ? (
      <NamedLink {...rootProps} {...linkProps}>
        {image}
      </NamedLink>
    ) : (
      <div {...rootProps}>{image}</div>
    );
  }

  const image = <span className={css.initials}>{abbreviatedName}</span>;

  return profileLinkEnabled ? (
    <NamedLink {...rootProps} {...linkProps}>
      {image}
    </NamedLink>
  ) : (
    <div {...rootProps}>{image}</div>
  );
};

AvatarComponent.defaultProps = {
  className: null,
  sizeClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
  noProfileImageIcon: null,
};

AvatarComponent.propTypes = {
  sizeClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,
  noProfileImageIcon: oneOfType([elementType, object]),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;

export const AvatarSmall = (props) => (
  <Avatar sizeClassName={css.smallAvatar} renderSizes={AVATAR_SIZES} {...props} />
);
AvatarSmall.displayName = 'AvatarSmall';

export const AvatarMedium = (props) => (
  <Avatar sizeClassName={css.mediumAvatar} renderSizes={AVATAR_SIZES_MEDIUM} {...props} />
);
AvatarMedium.displayName = 'AvatarMedium';

export const AvatarLarge = (props) => (
  <Avatar sizeClassName={css.largeAvatar} renderSizes={AVATAR_SIZES_LARGE} {...props} />
);
AvatarLarge.displayName = 'AvatarLarge';
