import React, { FC, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Builder } from '@builder.io/react';
import { AuthenticationModal, BuilderSection, Button, ShoppingBagButton, TopbarLogo } from '..';
import { useShoppingBag } from '../../hooks/useShoppingBag';
import { useIsShopSideLaunched } from '../../hooks/useIsShopSideLaunched';
import { redirectToURLWithModalState } from '../../util/window';
import { ButtonVariant } from '../Button/Button';
import IconHamburgerMenu from '../Icons/IconHamburgerMenu/IconHamburgerMenu';
import { ModalParams } from '../../util/urlHelpers';
import { Feature } from '../../util/featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { BuilderSections } from '../../util/builder';
import { useShopConfig } from '../../hooks/shopConfig';
import TopbarSearch from '../Topbar/TopbarSearch';
import { fetchShoppingBagListings } from '../../ducks/shoppingBag.duck';
import AppContext from '../../context/AppContext';
import { getCartListingsFromPG } from '../../util/cartListingHelpers';
import { cartListingIdsUpdated } from '../../ducks/user.duck';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import css from './TopbarMobile.module.css';

const HamburgerMenu = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const handleMobileMenuOpen = () => {
    redirectToURLWithModalState(history, location, ModalParams.MobileMenu);
  };

  return (
    <Button
      rootClassName={css.menu}
      onClick={handleMobileMenuOpen}
      title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
      variant={ButtonVariant.Custom}
    >
      <IconHamburgerMenu className={css.menuIcon} />
    </Button>
  );
};

const IconLinksMobile = () => {
  const isSearchModalEnabled = useFeatureFlags(Feature.SearchBarV2);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { shoppingBagCount } = useShoppingBag();
  const isShopSideLaunched = useIsShopSideLaunched();
  const { allowBuy } = useEnabledCustomerExperiences();
  const isTransparentTopbar = useContext(TopbarTransparencyContext);
  const { treetId } = useContext(AppContext);
  const userState = useSelector<any>((state) => state.user) as any;
  const { currentUser } = userState;

  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const handleMobileShoppingBagOpen = async () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      redirectToURLWithModalState(history, location, ModalParams.MobileShoppingBag);
      const stateCartListingIds = userState.cartListingIds || [];
      const pgCartListingIds = await getCartListingsFromPG(currentUser, treetId);
      if (!isEqual(stateCartListingIds, pgCartListingIds)) {
        await dispatch(cartListingIdsUpdated(pgCartListingIds));
        dispatch(fetchShoppingBagListings());
      }
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
        {isSearchModalEnabled && allowBuy && isShopSideLaunched && <TopbarSearch />}
        <ShoppingBagButton
          count={shoppingBagCount}
          isOpen={false}
          onClick={handleMobileShoppingBagOpen}
          rootClassName={classNames({
            [css.shoppingBagHidden]: !allowBuy || (allowBuy && !isShopSideLaunched),
          })}
          isTransparentTopbar={isTransparentTopbar}
        />
      </Box>
      <AuthenticationModal
        open={isAuthModalOpen}
        handleClose={() => setIsAuthModalOpen(false)}
        onSuccess={() => setIsAuthModalOpen(false)}
        bannerText="Sign up or log in to view your shopping bag."
      />
    </>
  );
};

const TopbarMobile: FC = () => {
  const { builderConfig } = useShopConfig();

  const topbarMobileContentSectionId =
    builderConfig?.sections?.[BuilderSections.TopbarMobileContent];

  if (topbarMobileContentSectionId) {
    return (
      <BuilderSection
        sectionType={BuilderSections.TopbarMobileContent}
        sectionId={topbarMobileContentSectionId}
      />
    );
  }

  return (
    <Box display="flex" alignItems="center" height="100%" px={1}>
      <Box className={css.menuSection}>
        <Box mx={1}>
          <HamburgerMenu />
        </Box>
      </Box>
      <TopbarLogo />
      <Box className={css.linkSection} mr={1}>
        <IconLinksMobile />
      </Box>
    </Box>
  );
};

Builder.registerComponent(HamburgerMenu, { name: 'Hamburger Menu' });
Builder.registerComponent(IconLinksMobile, { name: 'Icon Links Mobile' });

export default TopbarMobile;
