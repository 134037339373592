/* eslint-disable import/prefer-default-export */
import {
  Currency,
  DiscountValueType,
  LineItemCode,
} from '../types/apollo/generated/types.generated';
import { BundleInfo } from '../types/models/bundle';
import { CheckoutLineItem } from '../types/models/lineItem';

export const SIGNATURE_CONFIRMATION_SHIPPING_LINE_ITEM_NAME = 'Signed Delivery';

export const getListingsTotal = (lineItems: CheckoutLineItem[]): number => {
  let listingsTotal = 0;
  lineItems.forEach((lineItem) => {
    if (lineItem.code === LineItemCode.Listing) {
      const { price, quantity } = lineItem.listingLineItem!;
      listingsTotal += price * quantity;
    }
  });
  return Math.round(listingsTotal);
};

export const getShippingTotal = (lineItems: CheckoutLineItem[]): number => {
  let shippingTotal = 0;
  lineItems.forEach((lineItem) => {
    if (lineItem.code === LineItemCode.Shipping) {
      const { price, quantity } = lineItem.shippingLineItem!;
      shippingTotal += price * quantity;
    }
  });
  return Math.round(shippingTotal);
};

export const getReturnInsuranceTotal = (lineItems: CheckoutLineItem[]): number => {
  let returnInsuranceTotal = 0;
  // There should only be one return insurance purchased
  const returnInsuranceItem = lineItems.find(
    (lineItem) => lineItem.code === LineItemCode.ReturnInsurance
  );
  if (returnInsuranceItem) {
    returnInsuranceTotal = returnInsuranceItem.returnInsuranceLineItem!.price;
  }

  return Math.round(returnInsuranceTotal);
};

export const getDiscountTotal = (lineItems: CheckoutLineItem[]): number => {
  const listingsTotal = getListingsTotal(lineItems);

  const discountItems = lineItems.filter((lineItem) => lineItem.code === LineItemCode.Discount);

  const percentageDiscount = discountItems.find(
    (discountItem) => discountItem!.discountLineItem!.valueType === DiscountValueType.Percentage
  );

  const discountTotal = percentageDiscount
    ? listingsTotal * percentageDiscount!.discountLineItem!.value
    : discountItems.reduce((acc, discountItem) => acc + discountItem.discountLineItem!.value, 0);

  return Math.round(discountTotal);
};

export const getTaxTotal = (lineItems: CheckoutLineItem[]): number => {
  let taxTotal = 0;
  lineItems.forEach((lineItem) => {
    if (lineItem.code === LineItemCode.Tax) {
      const { value } = lineItem.taxLineItem!;
      taxTotal += value;
    }
  });
  return Math.round(taxTotal);
};

export const calculateLineItemTotal = (lineItems: CheckoutLineItem[]): number => {
  const listingsTotal = getListingsTotal(lineItems);
  const shippingTotal = getShippingTotal(lineItems);
  const returnInsuranceTotal = getReturnInsuranceTotal(lineItems);
  const discountTotal = getDiscountTotal(lineItems);
  const taxTotal = getTaxTotal(lineItems);
  const totalCharges = listingsTotal + shippingTotal + returnInsuranceTotal + taxTotal;
  return Math.round(Math.max(totalCharges - discountTotal, 0));
};

// Ensure the calculation for the return amount matches the backend logic.
// Reference: https://github.com/TreetCo/treet/blob/2af5dbd6a5fa043ffaffa5ed5bc5448dc01caa86/server/queues/handleBundleUpdates/handleBundleUpdatesUtils.ts#L266
export const calculateReturnTotal = ({
  bundle,
  disputedBundleItemIds,
  returnShippingPrice,
}: {
  bundle: BundleInfo;
  disputedBundleItemIds: string[];
  returnShippingPrice?: { currency: Currency; price: number };
}): number => {
  const disputedListingIds = new Set<string>(
    bundle?.bundleItems
      ?.filter(({ id }) => disputedBundleItemIds.includes(id))
      ?.map(({ listing: { sharetribeListingId } }) => sharetribeListingId)
  );

  const lineItems = bundle?.lineItems ?? [];
  const listingLineItems = lineItems.filter(({ code }) => code === LineItemCode.Listing);

  const lineItemsWithoutNonDisputedListings = lineItems.filter(
    ({ listingLineItem }) =>
      !(
        listingLineItem?.sharetribeListingId &&
        !disputedListingIds.has(listingLineItem.sharetribeListingId)
      )
  );

  const disputedListingLineItems = listingLineItems.filter(
    ({ listingLineItem }) =>
      listingLineItem?.sharetribeListingId &&
      disputedListingIds.has(listingLineItem.sharetribeListingId)
  );

  const disputedTaxLineItems = lineItems.filter(
    ({ code, taxLineItem }) =>
      code === LineItemCode.Tax &&
      taxLineItem?.metadata?.sharetribeListingId &&
      disputedListingIds.has(
        (taxLineItem?.metadata as { sharetribeListingId: string }).sharetribeListingId
      )
  );

  const disputedListingsLineItemsTotal =
    getListingsTotal(disputedListingLineItems as CheckoutLineItem[]) / 100;
  const disputedTaxLineItemsTotal = getTaxTotal(disputedTaxLineItems as CheckoutLineItem[]) / 100;
  const disputedDiscountLineItemsTotal =
    getDiscountTotal(lineItemsWithoutNonDisputedListings as CheckoutLineItem[]) / 100;
  const disputedRefundableLineItemsTotal =
    disputedListingsLineItemsTotal + disputedTaxLineItemsTotal + disputedDiscountLineItemsTotal;
  const returnShippingPriceTotal = returnShippingPrice?.price ? returnShippingPrice.price / 100 : 0;

  return disputedRefundableLineItemsTotal - returnShippingPriceTotal;
};
