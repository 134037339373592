import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const shrimpsSizeOptions = [
  'UK 6',
  'UK 8',
  'UK 10',
  'UK 12',
  'UK 14',
  'UK 16',
  'UK 18',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
];

export const shrimpsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: shrimpsSizeOptions }),
    getConditionsFilterConfig(),
  ],
};
