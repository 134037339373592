import React from 'react';
import { Box, Grid } from '@material-ui/core';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { BundleInfo } from '../../types/models/bundle';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { LineItemCode } from '../../types/apollo/generated/types.generated';
import ReturnLineItemBreadown from './ReturnLineItemBreakdown';
import ItemizedListing from '../ItemizedListing/ItemizedListing';
import { CheckoutLineItem } from '../../types/models/lineItem';

export const DISPUTE_MODAL_SUMMARY_PAGE_REFERRER_LOCATION = 'Dispute Modal Summary Page';

interface ReturnSummaryPageProps {
  bundle?: BundleInfo;
  bundleItemToListingIdMap?: Map<string, string>;
  disputedBundleItemIds?: string[];
  listingsMap?: Map<string, ListingWithImages>;
}

const ReturnSummaryPage = ({
  bundle,
  bundleItemToListingIdMap,
  disputedBundleItemIds,
  listingsMap,
}: ReturnSummaryPageProps) => {
  const returnItemizedListings = disputedBundleItemIds?.map((bundleItemId) => {
    const sharetribeListingId = bundleItemToListingIdMap?.get(bundleItemId) ?? '';
    const listing = listingsMap?.get(sharetribeListingId);

    const listingLineItem = bundle?.lineItems.find(
      (lineItem) =>
        lineItem.code === LineItemCode.Listing &&
        lineItem.listingLineItem?.sharetribeListingId === listing?.id.uuid
    );

    return (
      listing && (
        <ItemizedListing
          lineItem={listingLineItem as CheckoutLineItem}
          referrerForLogging={DISPUTE_MODAL_SUMMARY_PAGE_REFERRER_LOCATION}
          listing={listing}
        />
      )
    );
  });

  return (
    <Box p={2}>
      <Grid container alignItems="center" spacing={2}>
        {returnItemizedListings}
        <ReturnLineItemBreadown bundle={bundle} disputedBundleItemIds={disputedBundleItemIds} />
        <Box mt={2}>
          <TypographyWrapper variant="body2">
            A return label will be sent to your email address. Once your return is received and
            processed, you will be refunded the above amount.
          </TypographyWrapper>
        </Box>
      </Grid>
    </Box>
  );
};

export default ReturnSummaryPage;
