/* eslint-disable import/prefer-default-export */
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getColorFilterConfig } from '../filters/color';

const portlandleatherSizes: string[] = [
  'X-Small',
  'Small',
  'Medium',
  'Large',
  'X-Large',
  'XX-Large',
  'Oversized',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
];
const portlandleatherAdditionalPayoutSettings = {
  additionalShopifyGiftCardRequirements: {
    giftCardExpiresAfterDays: 365, // 1 year
  },
  creditCodeHelperText: 'Credits expire 1 year after date of issue.',
};

const portlandleatherConditionsOptions = [
  {
    key: Conditions.NewWithoutTags,
    label: 'New',
    description:
      'Your item has never been worn (other than perhaps trying it on). There are no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    description:
      'Your item has only been lightly used and has been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    description:
      'You’ve worn this item and it’s well maintained. There may be some quirks like a small stain, a button missing, or a loose thread, but it’s overall in good shape. You will need to take pictures of any quirks the item has.',
  },
];

export const portlandleatherShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({
      options: portlandleatherConditionsOptions,
    }),
    getColorFilterConfig(),
    getSizeFilterConfig({ sizes: portlandleatherSizes }),
  ],
  additionalPayoutSettings: portlandleatherAdditionalPayoutSettings,
  // customizable name for store credit
  storeCreditName: 'merchandise credit',
};
