import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { BuilderSections } from '../../util/builder';

const joanieSizes = [
  'UK 4',
  'UK 5',
  'UK 6',
  'UK 7',
  'UK 8',
  'UK 9',
  'UK 10',
  'UK 11',
  'UK 12',
  'UK 14',
  'UK 16',
  'UK 18',
  'UK 20',
  'UK 22',
  'EXTRA SMALL (UK 4-6)',
  'S (UK 8-10)',
  'M (UK 12-14)',
  'L (UK 16-18)',
  'XL (UK 20-22)',
];

export const joanieShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: joanieSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.TopbarContent]: '414f0c3392974f039cb6d02d43966aba',
      [BuilderSections.LandingPageContent]: '077b23d2a4d940209e080141b99232b1',
    },
  },
};
