/* eslint-disable import/prefer-default-export */
import { ListingCondition } from '@prisma/client';
import { Conditions } from '../../shopConfig/filters/condition';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { ListingState } from '../apollo/generated/types.generated';

export const ST_LISTING_STATE_TO_LISTING_STATUS: {
  [sharetribeState: string]: ListingState;
} = {
  // TODO: add ListingState.DRAFT
  [LISTING_STATE_PENDING_APPROVAL]: ListingState.PendingApproval,
  [LISTING_STATE_PUBLISHED]: ListingState.Open,
  [LISTING_STATE_CLOSED]: ListingState.Closed,
};

export const ST_CONDITION_TO_LISTING_CONDITION: { [key: string]: ListingCondition } = {
  [Conditions.NewWithTags]: ListingCondition.NEW_WITH_TAGS,
  [Conditions.NewWithoutTags]: ListingCondition.NEW_WITHOUT_TAGS,
  [Conditions.Excellent]: ListingCondition.EXCELLENT,
  [Conditions.Good]: ListingCondition.GOOD,
  [Conditions.MinorDefect]: ListingCondition.MINOR_DEFECT,
};
