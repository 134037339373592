import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const waterlustSizeOptions = [
  '[XS] Extra Strong',
  '[S] Strong',
  '[M] Mighty',
  '[L] Legendary',
  '[XL] Extra Legendary',
  '[2XL] Extra Extra Legendary',
  '[3XL] Extra Extra Extra Legendary',
];

export const waterlustShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: waterlustSizeOptions }),
  ],
};
