import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useShopConfig } from '../../hooks/shopConfig';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useTax } from '../../hooks/useTax';
import { LineItemCode } from '../../types/apollo/generated/types.generated';
import {
  calculateReturnTotal,
  getDiscountTotal,
  getListingsTotal,
  getTaxTotal,
} from '../../util/lineItems';
import { BundleInfo } from '../../types/models/bundle';
import DynamicValueWrapper from '../DynamicValueWrapper/DynamicValueWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import Divider from '../Divider/Divider';
import { CheckoutLineItem } from '../../types/models/lineItem';

interface ReturnLineItemBreakdownProps {
  bundle?: BundleInfo;
  disputedBundleItemIds?: string[];
}

const ReturnLineItemBreadown = (props: ReturnLineItemBreakdownProps) => {
  const { bundle, disputedBundleItemIds } = props;

  const intl = useIntl();
  const { css: brandCss, returnShippingPrice } = useShopConfig();
  const { currencyConfig } = useBrandCountryConfig();
  const { isTaxEnabled } = useTax();

  const bgcolor = brandCss?.backgroundColor || 'white';

  const disputedListingIds = new Set<string>(
    bundle?.bundleItems
      .filter(({ id }) => disputedBundleItemIds?.includes(id))
      .map(({ listing: { sharetribeListingId } }) => sharetribeListingId)
  );
  const lineItems = bundle?.lineItems ?? [];

  const listingLineItems =
    bundle?.lineItems.filter(({ code }) => code === LineItemCode.Listing) ?? [];

  const lineItemsWithoutNonDisputedListings = lineItems.filter(
    ({ listingLineItem }) =>
      !(
        listingLineItem?.sharetribeListingId &&
        !disputedListingIds.has(listingLineItem.sharetribeListingId)
      )
  );

  const disputedListingLineItems = listingLineItems.filter(
    ({ listingLineItem }) =>
      listingLineItem?.sharetribeListingId &&
      disputedListingIds.has(listingLineItem.sharetribeListingId)
  );

  const disputedTaxLineItems = lineItems.filter(
    ({ code, taxLineItem }) =>
      code === LineItemCode.Tax &&
      taxLineItem?.metadata?.sharetribeListingId &&
      disputedListingIds.has(
        (taxLineItem?.metadata as { sharetribeListingId: string }).sharetribeListingId
      )
  );

  const taxLineItemName = disputedTaxLineItems.find(
    (lineItem) => lineItem.code === LineItemCode.Tax
  )?.name;

  const disputedListingsLineItemsTotal =
    getListingsTotal(disputedListingLineItems as CheckoutLineItem[]) / 100;
  const disputedTaxLineItemsTotal = getTaxTotal(disputedTaxLineItems as CheckoutLineItem[]) / 100;
  const disputedDiscountLineItemsTotal =
    getDiscountTotal(lineItemsWithoutNonDisputedListings as CheckoutLineItem[]) / 100;
  const disputedRefundableLineItemsTotal =
    disputedListingsLineItemsTotal + disputedTaxLineItemsTotal + disputedDiscountLineItemsTotal;
  const returnShippingPriceTotal = returnShippingPrice?.price ? returnShippingPrice.price / 100 : 0;
  const refundTotal =
    bundle && disputedBundleItemIds
      ? calculateReturnTotal({ bundle, disputedBundleItemIds, returnShippingPrice })
      : 0;

  return (
    <Box mt={2} display="flex" flexDirection="column" width="100%" bgcolor={bgcolor}>
      <Divider />
      <Box py={2}>
        {/* This conditional is needed because we only want to render certain UI elements
     when there's more than just listing line items present, and we want to avoid
     displaying these elements when there are only listing line items. */}
        {(disputedDiscountLineItemsTotal > 0 || disputedTaxLineItemsTotal > 0) && (
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body2">Items</Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              <DynamicValueWrapper>
                {intl.formatNumber(disputedListingsLineItemsTotal, currencyConfig)}
              </DynamicValueWrapper>
            </Typography>
          </Box>
        )}
        {isTaxEnabled && disputedTaxLineItemsTotal > 0 && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Typography variant="body2" display="inline">
              {taxLineItemName || 'Tax'}
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
              {intl.formatNumber(disputedTaxLineItemsTotal, currencyConfig)}
            </Typography>
          </Box>
        )}
        {disputedDiscountLineItemsTotal > 0 && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Typography variant="body2" display="inline">
              Discount
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
              {intl.formatNumber(disputedDiscountLineItemsTotal, currencyConfig)}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" display="inline">
            Total
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
            {intl.formatNumber(disputedRefundableLineItemsTotal, currencyConfig)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" display="inline">
            Return Shipping
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
            -
            <DynamicValueWrapper>
              {intl.formatNumber(returnShippingPriceTotal, currencyConfig)}
            </DynamicValueWrapper>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Total Refund
          </Typography>
          <Typography
            variant="body2"
            style={{ color: defaultTreetStyles.red60, fontWeight: 'bold' }}
          >
            <DynamicValueWrapper>
              {intl.formatNumber(refundTotal, currencyConfig)}
            </DynamicValueWrapper>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnLineItemBreadown;
