import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconCheckCircleProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}

const IconCheckCircle: React.FC<IconCheckCircleProps> = (props) => {
  const { className, color, width, height } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4ZM6 16C6 21.5228 10.4772 26 16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconCheckCircle;
