import moment from 'moment';
import { ShopIntegrationType } from '../../types/apollo/generated/types.generated';

export enum AdminPageTab {
  MarketplaceDashboard = 'marketplace-dashboard',
  TradeInDashboard = 'trade-in-dashboard',
  Integrations = 'integrations',
  Disbursements = 'disbursements',
  Reports = 'reports',
  InventoryUpload = 'inventory-upload',
  Feedback = 'give-feedback',
  SchedulePromotion = 'schedule-promotion',
  Billing = 'billing',
}

export enum IntegrationPane {
  Description = 'description',
  Settings = 'settings',
}

export enum ShopIntegrationTypePathId {
  LoyaltyLionRewards = 'loyalty-lion-rewards',
}

export const SHOP_INTEGRATION_TYPE_TO_PATH_ID: { [key in ShopIntegrationType]: string } = {
  [ShopIntegrationType.LoyaltylionRewards]: ShopIntegrationTypePathId.LoyaltyLionRewards,
};

export const RETOOL_EMBED_TABS = [AdminPageTab.MarketplaceDashboard, AdminPageTab.TradeInDashboard];

export const formatDisbursementDate = (date: Date, dateFormat?: string) =>
  moment.utc(date).format(dateFormat || 'YYYY-MM-DD');
