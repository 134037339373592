/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const schutzSizeOptions = [
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  'O/S',
  'S',
  'M',
  'L',
];

export const schutzShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: schutzSizeOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.TopbarContent]: '8dca54c34b8a443a8801d11fb9114599',
      [BuilderSections.LandingPageContent]: '6b11663a4d08418ca1aeede726572584',
      [BuilderSections.AboutPageHeroContent]: '3105df094d6c40a3902fafa36844e276',
    },
  },
};
